(function(w,d){

    // return false;

    w.lazyFunctions = {
        defaultSwiper: async element => {
            const {default: swiper} = await import ('./defaultSwiper.js');
            return Promise.resolve(swiper(element));
        },
        galeria: async element => {
            const {default: fotos} = await import ('./galeria.js');
            return Promise.resolve(fotos(element));
        },
        formInscricao: async element => {
            const {default: form} = await import ('./inscricao.js');
            return Promise.resolve(form(element));
        },
        youtube: async element => {
            const {default: you_tube} = await import ('./youtube.js');
            return Promise.resolve(you_tube(element));
        }
    };
    
    w.addEventListener("LazyLoad::Initialized", event => w.lazyLoadInstance = event.detail.instance, false);

    d.addEventListener('minimal-user-interaction', function (event) {
        console.log('minimal-user-interaction');
        const __loader = async () => {
            try {
                const LazyLoad = await import('vanilla-lazyload');
                const lazy = new LazyLoad({
                    unobserve_entered: true,
                    elements_selector: ".lazy, iframe[data-src], [class^='fb-'], .instagram-media, .twitter-tweet, [class^='sharethis-inline-share-buttons']",
                    callback_enter: (element) => {

                        w.dataLayer = w.dataLayer || [];

                        let eventName = null;
                        let removeEntered = false;

                        // console.log('estou aqui 123');

                        if(element.hasAttribute('data-lazy-function')) {
                            const lazyFunctionName = element.getAttribute("data-lazy-function");
                            const lazyFunction = w.lazyFunctions[lazyFunctionName];
                            if (!lazyFunction) return;
                            return lazyFunction(element);
                        } else if(element.hasAttribute('data-lazy-event')) {
                            eventName = element?.dataset?.lazyEvent || null;
                        } else {
    
                            const classList = String(element.classList);

                            if(classList.match(/fb-*/gi) || classList.match(/instagram-media/gi)) {
                                eventName = 'instagram-event';
                                removeEntered = true;
                                setTimeout(() => w.dataLayer.push({'event': 'facebook-sdk-event'}), 1000);
                            } else if(classList.match(/taboola/gi)) {
                                //  eventName = 'LoaderTaboolaFeed';
                            } else if(classList.match(/twitter-tweet/gi)) {
                                eventName = 'twitter-event';
                            }

                        }
                        
                        if(eventName) {
                            console.log(eventName, 'eventName');
                            if(removeEntered) element.classList.remove('entered');
                            w.dataLayer.push({'event': eventName});
                        }
                    }
                });
    
            } catch(err) {
                console.error('erro ao carregar lazyload', err);
            }
        };
        __loader().then(() => console.log('ok')).catch(() => console.error('failed'));
        return false;
    });

    const gtm_container = document.getElementById('gtm-container');
    // console.log(gtm_container);
    if(gtm_container) {
        const gtm_id = gtm_container?.dataset?.id || null;
        if(gtm_id) {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(w,d,'script','dataLayer',gtm_id.toString());
            console.log('gtm loaded', gtm_id);
        }
    }

    const toggleButton = d.getElementById('toggleButton');
    if(toggleButton) {
        const cardContainer = d.getElementById('cardContainer');
        const closeButton = d.getElementById('closeButton');
    
        toggleButton.addEventListener('click', () => {
            console.log(cardContainer.style.left)
            cardContainer.style.left = !cardContainer.style.left || cardContainer.style.left === '-320px' ? '0' : '-320px';
        });
    
        closeButton.addEventListener('click', () => {
            cardContainer.style.left = '-320px';
        });    
    }

    //  jQuery(document).on('click', '#'+n+' .wrapper', e => jQuery(e.target).closest('.card2').toggleClass('flipped'));
    const carouselParticipantees = d.querySelector('#ParticipantesCarousel');
    if(carouselParticipantees) {
        carouselParticipantees.addEventListener('click', e => {
            if(e.target.tagName != 'A') {
                e.preventDefault();
                e.target.closest('.card2').classList.toggle('flipped');
            }
            return true;
        });
        // const wrapper = carouselParticipantees.querySelector(':scope .wrapper');
        // if(wrapper) {
        //     wrapper.addEventListener('click', e => {
        //         const card = e.target.querySelector(':scope > .card2');
        //         card.classList.toggle('flipped');
        //         console.log(e);
        //     });
        // }
        // console.log(wrapper);
        // carouselParticipantees.querySelector(':scope > .wrapper').addEventListener('click', e => {
        //     console.log(e);
        // });
    }

    console.log('main app loaded', carouselParticipantees, toggleButton);

})(window,document);